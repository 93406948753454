<template>
  <main>
    <router-view/>
  </main>
  <footer></footer>
</template>

<style>
main {position: relative;}

footer {
  padding: 3em;
  padding-bottom: 0em;
}
</style>
